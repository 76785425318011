<template>
  <el-dialog title="种药肥使用情况" :visible="showSeedRecordsFlag" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
    <el-table v-if="records.length" ref="multipleTable" :data="records" stripe>
      <template slot="empty"> 暂无数据 </template>
      <el-table-column prop="agriculturalProductionName" label="农资名称"> </el-table-column>
      <el-table-column prop="resourceTypeName" label="农资类型"> </el-table-column>
      <el-table-column label="使用量">
        <template slot-scope="scope">
          {{ scope.row.amount | absAmount }}
        </template>
      </el-table-column>
      <el-table-column prop="countPerUnit" label="数量"> </el-table-column>
      <el-table-column prop="unitSpecs" label="规格"> </el-table-column>
      <el-table-column label="使用时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateFormat }}
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import { productionRecord } from '@/api/stockManage4/api.js';
import dayjs from 'dayjs';
export default {
  name: 'productRecords',
  props: {
    landInfo: null,
    showSeedRecordsFlag: false
  },
  watch: {
    showSeedRecordsFlag: {
      handler(val) {
        if (val) {
          this.getProductionRecord();
        }
      },
      immediate: true
    }
  },
  filters: {
    dateFormat(val) {
      return dayjs(val).format('YYYY-MM-DD');
    },
    absAmount(val) {
      return Math.abs(val);
    }
  },
  data() {
    return {
      records: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      }
    };
  },
  methods: {
    closeDg() {
      this.$emit('update:showSeedRecordsFlag', false);
    },
    getProductionRecord() {
      let params = {
        id: this.landInfo.landId,
        seasonId: this.landInfo.seasonId,
        pageSize: this.pageInfo.pageSize,
        pageNum: this.pageInfo.currentPage
      };
      productionRecord(params)
        .then((res) => {
          this.records = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getProductionRecord();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-pagination {
  margin-top: 20px;
}
</style>
