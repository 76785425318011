<!-- //科技管理 -->
<template>
  <div>
    <div :class="['main-content-wrap', drawer ? 'short-width' : '']">
      <el-col :span="6" class="flex-row">
        <span class="span1">选择作物</span>
        <el-select clearable v-model="categoryId" placeholder="请选择" @change="onCategoryChange">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <span class="span1">种植日期</span>
        <el-date-picker
          style="width: 60%"
          v-model="plantDate"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </el-col>
      <el-col :span="5" class="flex-row">
        <span class="span1">地块名称</span>
        <el-input v-model="landName" style="width: 60%" placeholder="请输入地块名称" clearable></el-input>
      </el-col>
      <el-col :span="5" class="flex-row">
        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
        <el-button type="info" @click="resetSearch" icon="el-icon-refresh-left">重置</el-button>
      </el-col>
      <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
        <template slot="empty">
          <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
        </template>
        <el-table-column prop="landName" label="地块名称"></el-table-column>
        <el-table-column label="地块面积">
          <template slot-scope="scope"> {{ formatPlantArea(scope.row.landArea) }}亩</template>
        </el-table-column>
        <el-table-column prop="cropsACategoryName" label="种植作物"> </el-table-column>
        <el-table-column label="种植面积">
          <template slot-scope="scope"> {{ scope.row.plantingArea }}亩</template>
        </el-table-column>
        <el-table-column label="种植日期">
          <template slot-scope="scope"> {{ scope.row.plantingDate | filterDate }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toDetail(scope.row, 0)">种药肥使用情况</el-button>
            <el-button type="text" size="small" @click="toDetail(scope.row, 1)">长势分析</el-button>
            <el-button type="text" size="small" @click="toDetail(scope.row, 3)">诊断分析</el-button>
            <!-- <template v-if="hasId(scope.row.landId)">
              <div class="disable-btn"></div>

              <el-button disabled type="info" plain size="small">已加入</el-button>
            </template> -->
          </template>
        </el-table-column>
        <el-table-column label="对比">
          <template slot-scope="scope">
            <el-button
              :icon="hasId(scope.row.landId) ? '' : 'el-icon-plus'"
              :type="hasId(scope.row.landId) ? 'info' : 'success'"
              :disabled="hasId(scope.row.landId) ? true : false"
              plain
              size="small"
              @click="addInfo(scope.row)"
              >{{ hasId(scope.row.landId) ? '已加入' : '对比' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagenation">
        <el-pagination
          v-if="pageInfo.totalPage > 0"
          background
          @current-change="handleSizeChange"
          :current-page.sync="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          layout="total, prev, pager, next"
          :total="pageInfo.totalPage"
        >
        </el-pagination>
      </div>
    </div>
    <seedRecords :landInfo="landInfo" :showSeedRecordsFlag.sync="showSeedRecordsFlag"></seedRecords>
    <rsRecords
      :landId="landInfo.landId"
      :landInfo="landInfo"
      :remoteSenseType="remoteSenseType"
      :showRsRecordsFlag.sync="showRsRecordsFlag"
      v-if="showRsRecordsFlag"
    ></rsRecords>
    <el-drawer title="" :visible.sync="drawer" :with-header="false" :size="400" :modal="false">
      <div class="drawer-header">
        <div class="header-left">对比分析</div>
        <div class="collapse-wrap">
          <i class="arrow-icon icon iconfont icon-zhankai" @click="closeDrawer"></i>
        </div>
      </div>
      <div class="drawer-content">
        <div class="drawer-item" v-for="item in addList" :key="item.landId"
          >{{ item.landName }}
          <span class="icon iconfont icon-guanbi" @click="removeInfo(item.landId)"></span>
        </div>
      </div>
      <div class="drawer-footer">
        <el-button icon="el-icon-check" :disabled="addList.length > 1 ? false : true" type="success" plain size="small" @click="contrastStart"
          >开始对比</el-button
        >
        <span class="tips">注：最多选择3个作物对比</span>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import seedRecords from './components/seedRecords.vue';
import rsRecords from './components/rsRecords.vue';
import { guiDanceHistory } from '@/api/stockManage4/api.js';
import { categoryList } from '@/api/api.js';
import dayjs from 'dayjs';
import * as utils from '@/utils/utils.js';
export default {
  components: {
    seedRecords,
    rsRecords
  },
  props: {
    sysUsers: [],
    farmingTypes: [],
    farmingNameList: [],
    plantLands: []
  },
  data() {
    return {
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: [],
      seedRecords: [],
      showSeedRecordsFlag: false,
      showRsRecordsFlag: false,
      landInfo: null,
      remoteSenseType: 1,
      categoryList: [],
      categoryId: '', // 作物种类
      plantDate: '',
      landName: '',
      drawer: false,
      addList: []
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getCategoryList();
  },
  filters: {
    filterDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      }
      return '-';
    }
  },
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    hasId(id) {
      let flag = false;
      this.addList.forEach((item) => {
        if (item.landId == id) {
          flag = true;
        }
      });
      return flag;
    },
    getList(pageNum) {
      if (pageNum) {
        this.pageInfo.currentPage = pageNum;
      }
      guiDanceHistory({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        cropsACategoryId: this.categoryId,
        landName: this.landName,
        startPlantingTime: this.plantDate && this.plantDate[0] ? dayjs(this.plantDate[0]).format('YYYY-MM-DD 00:00:00') : '',
        endPlantingTime: this.plantDate && this.plantDate[1] ? dayjs(this.plantDate[1]).format('YYYY-MM-DD 23:59:59') : ''
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    getCategoryList() {
      categoryList()
        .then((res) => {
          this.categoryList = res;
        })
        .catch((error) => {});
    },
    onCategoryChange(id) {
      console.log(this.categoryId, '=========');
      // this.categoryId = id;
      // this.categoryList.forEach(e => {
      //   if (id == e.id) {
      //     this.cropsList = e.species || [];
      //   }
      // });
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    stageChange(val) {
      this.farmingNameList.map((item) => {
        if (item.stageCode == val) {
          this.farmingTypeNames = item.farmingTypes;
        }
      });
      // this.farmingTypeNames = this.farmingNameList[val];
      this.farmingTypeId = '';
    },
    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    showRecords(row) {
      this.records = row.productionRecords || [];
      if (this.records.length) {
        this.showRecordsFlag = true;
      }
    },
    toDetail(item, type) {
      console.log(item);
      this.landInfo = item;
      if (type == 0) {
        this.showSeedRecordsFlag = true;
      } else {
        this.remoteSenseType = type;
        this.showRsRecordsFlag = true;
      }
    },
    addInfo(item) {
      let msg = '';
      let obj = {
        landName: item.landName,
        landId: item.landId,
        cropsACategoryId: item.cropsACategoryId
      };
      if (this.addList.length > 2) {
        msg = '最多选择3个作物对比';
      }
      // else {
      //   this.addList.forEach((info) => {
      //     if (info.cropsACategoryId != item.cropsACategoryId) {
      //       msg = '对比地块农作物品类不一致';
      //     }
      //   });
      // }
      if (msg) {
        this.$message.warning(msg);
      } else {
        this.addList.push(obj);
        this.drawer = true;
      }
    },
    removeInfo(id) {
      this.addList = this.addList.filter((item) => {
        return item.landId != id;
      });
    },
    closeDrawer() {
      this.drawer = false;
    },
    search() {
      this.getList();
    },
    resetSearch() {
      this.categoryId = '';
      this.plantDate = '';
      this.landName = '';
    },
    contrastStart() {
      let ids = [];
      this.addList.forEach((item) => {
        ids.push(item.landId);
      });
      ids.sort((a, b) => {
        return a - b;
      });
      this.$router.push({ name: 'analysisRes', query: { ids: JSON.stringify(ids) } });
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  .span1 {
    font-size: inherit;
    font-size: 12px;
    width: 70px;
  }
}
.means-num {
  color: #409eff;
  cursor: pointer;
}
.bottom12 {
  margin-bottom: 12px;
}
.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c3542;
  line-height: 16px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 1px solid #ebedf4;
  margin-bottom: 20px;
  .header-left {
    display: flex;
    align-items: center;
    &::before {
      display: block;
      content: '';
      width: 3px;
      height: 16px;
      background: #02b98f;
      margin-right: 4px;
    }
  }
  .icon {
    cursor: pointer;
  }
}
.drawer-item {
  width: 320px;
  margin: 10px 20px;
  height: 60px;
  background: #f7f8fa;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .icon {
    width: 14px;
    height: 14px;
    color: #c1c1c1;
    cursor: pointer;
  }
}
.el-drawer__wrapper {
  width: 400px;
  right: 0;
  left: auto;
}
.drawer-footer {
  margin: 20px;
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c1c1c1;
    margin-left: 10px;
  }
}
.short-width {
  width: calc(100% - 400px);
  flex: none;
}
</style>
